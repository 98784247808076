import "./App.css";
import "semantic-ui-css/semantic.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { Web3Provider } from "./context/web3Contexts";

import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Toaster } from 'react-hot-toast';
import Header from './components/Header';
import VerificationMessage from './components/VerificationMessage';
import NameSearch from './components/NameSearch';
import TwitterVerification from './components/TwitterVerification';
import CommunityCanvas from './components/CommunityCanvas';
import Leaderboard from './components/Leaderboard';
import WalletConnect from './components/WalletConnect';
import useWeb3 from './hooks/useWeb3';

function App() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const { account } = useWeb3();
  return (
    <Web3Provider>
      <ThirdwebProvider
        secretKey="V7k0g6MkpkD4bBepl4w1krdd67mOicvWXhe1yBweobCW3hRnJuUvu2RnsY-2hS_xAC48MyVzwzO7URx2HJdY2g"
        clientId="1b2e587a81384048d20a89994d7077e5"
        activeChain="sepolia"
      >

<div className="min-h-screen bg-black text-white">
      <Toaster position="top-center" />
      <Header />
      
      <main className="container mx-auto px-4 py-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h1 className="text-6xl font-mono font-bold bg-gradient-to-r from-red-500 to-red-800 bg-clip-text text-transparent mb-4">
            Quai Name Service
          </h1>
          <p className="text-xl text-gray-400 font-mono">
            Secure your unique .quai domain and join the future of Web3
          </p>
          {account && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="mt-4 text-lg font-mono text-red-400"
            >
              Balance:  $QNS
            </motion.div>
          )}
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <motion.div
            ref={ref}
            initial={{ opacity: 0, x: -50 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.5 }}
            className="space-y-8"
          >
            <WalletConnect />
            <VerificationMessage />
            <TwitterVerification account={account} />
            <NameSearch />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="space-y-8"
          >
            <CommunityCanvas />
            <Leaderboard />
          </motion.div>
        </div>
      </main>

      <footer className="mt-16 py-8 bg-red-900/10">
        <div className="container mx-auto px-4 text-center text-gray-400 font-mono">
          <p>© 2024 QNS - Building the Future of Quai Network</p>
        </div>
      </footer>
    </div>


      </ThirdwebProvider>
    </Web3Provider>
  );
}

export default App;
