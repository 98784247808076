import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Paintbrush, Save, Palette, Sparkles, Zap } from "lucide-react";
import toast from "react-hot-toast";
import { quais } from "quais";
import useWeb3 from "../hooks/useWeb3";
import { QnsFactoryAbi } from "../config/abi";
import { QnsFactoryAddress } from "../config/contracts";

const COLORS = [
  "#ff0000", // Red
  "#00ff00", // Green
  "#0000ff", // Blue
  "#ffff00", // Yellow
  "#ff00ff", // Magenta
  "#00ffff", // Cyan
  "#ffffff", // White
];

const CommunityCanvas = () => {
  const { account, signer, address } = useWeb3();

  const [canDraw, setCanDraw] = useState(true);
  const [timeLeft, setTimeLeft] = useState(3600);
  const [brushColor, setBrushColor] = useState(COLORS[0]);
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [context, setContext] = useState(null);
  const [remainingPaint, setRemainingPaint] = useState(100);
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (ctx) {
        ctx.strokeStyle = brushColor;
        ctx.lineWidth = 2;
        ctx.lineCap = "round";
        setContext(ctx);
      }
    }
  }, [brushColor]);

  useEffect(() => {
    if (!canDraw) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 0) {
            setCanDraw(true);
            return 3600;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [canDraw]);

  const startDrawing = async (e) => {
    // console.log(account.addr)
    if(!account) {
      toast.error("Connect your wallet first!");
      return;
    }
    try {
      const QnsFactory = new quais.Contract(
        QnsFactoryAddress,
        QnsFactoryAbi,
        signer
      );
      const isDraw = await QnsFactory.canDraw(account.addr);

      if (!isDraw) {
        toast.error(
          "You can't draw right now!, buy more paint to continue drawing"
        );
        return;
      }

      setIsDrawing(true);
      setShowMessage(false);
      const canvas = canvasRef.current;
      if (canvas && context) {
        const rect = canvas.getBoundingClientRect();
        const x =
          "touches" in e
            ? e.touches[0].clientX - rect.left
            : e.clientX - rect.left;
        const y =
          "touches" in e
            ? e.touches[0].clientY - rect.top
            : e.clientY - rect.top;
        context.beginPath();
        context.moveTo(x, y);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const draw = async (e) => {
    // if (!isDrawing || !canDraw || !account || remainingPaint <= 0) return;
    if(!account) {
      toast.error("Connect your wallet first!");
      return;
    }
    try {
      const QnsFactory = new quais.Contract(
        QnsFactoryAddress,
        QnsFactoryAbi,
        signer
      );
      const isDraw = await QnsFactory.canDraw(account.addr);

      if (!isDraw) {
        toast.error(
          "You can't draw right now!, buy more paint to continue drawing"
        );
        return;
      }

      const canvas = canvasRef.current;
      if (canvas && context) {
        const rect = canvas.getBoundingClientRect();
        const x =
          "touches" in e
            ? e.touches[0].clientX - rect.left
            : e.clientX - rect.left;
        const y =
          "touches" in e
            ? e.touches[0].clientY - rect.top
            : e.clientY - rect.top;
        context.lineTo(x, y);
        context.stroke();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const stopDrawing = () => {
    setIsDrawing(false);
    if (context) {
      context.closePath();
    }
  };

  const handleSave = async () => {
    if(!account) {
      toast.error("Connect your wallet first!");
      return;
    }
    try{

      const QnsFactory = new quais.Contract(
        QnsFactoryAddress,
        QnsFactoryAbi,
        signer
      );
      const cost = await QnsFactory.DRAW_COST();
      const tnx = await QnsFactory.saveDrawing("dhshs", {
        value: cost,
        gasLimit: 5000000,
      });

      console.log("Transaction broadcasted:", tnx.hash);
      const receipt = await tnx.wait();
      toast.success("Transaction Mined:", receipt.transactionHash);
      console.log("Contract deployed at:", receipt.contractAddress);

      toast(
        (t) => (
          <div className="flex items-center space-x-2">
            <Sparkles className="w-5 h-5 text-yellow-400 animate-pulse" />
            <span>Drawing saved! +20 QNS</span>
          </div>
        ),
        {
          icon: "🎨",
          style: {
            background: "linear-gradient(to right, #dc2626, #991b1b)",
            color: "#ffffff",
            borderRadius: "10px",
          },
          duration: 3000,
        }
      );
      setCanDraw(false);

    }catch(error){
      console.log(error);
    }
   
  };

  const buyMorePaint = async () => {
    try {
      const QnsFactory = new quais.Contract(
        QnsFactoryAddress,
        QnsFactoryAbi,
        signer
      );
      const cost = await QnsFactory.PAINT_COST();
      const tnx = await QnsFactory.buyPaint({
        value: cost,
        gasLimit: 5000000,
      });

      console.log("Transaction broadcasted:", tnx.hash);
      const receipt = await tnx.wait();
      toast.success("Transaction Mined:", receipt.transactionHash);
      console.log("Contract deployed at:", receipt.contractAddress);

      toast(
        (t) => (
          <div className="flex items-center space-x-2">
            <Palette className="w-5 h-5 text-yellow-400 animate-spin" />
            <span>Paint purchased! Time to create!</span>
          </div>
        ),
        {
          style: {
            background: "linear-gradient(to right, #dc2626, #991b1b)",
            color: "#ffffff",
            borderRadius: "10px",
          },
          duration: 3000,
        }
      );
      setRemainingPaint(100);
    } catch (error) {
      console.log(error);
      toast.error("Error data:", error.data);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="p-6 rounded-xl bg-gradient-to-br from-red-900/20 to-black border border-red-500/20 relative overflow-hidden"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-red-500/5 to-transparent animate-pulse" />

      <div className="relative">
        <div className="flex items-center justify-between mb-4">
          <motion.h2
            className="text-2xl font-mono font-bold text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-red-300"
            whileHover={{ scale: 1.05 }}
          >
            Community Canvas
          </motion.h2>
          <motion.div
            className="flex items-center space-x-2 bg-black/40 px-3 py-1 rounded-full"
            whileHover={{ scale: 1.05 }}
          >
            <span className="text-sm font-mono text-red-400">
              Paint: {remainingPaint}
            </span>
            <Palette className="w-5 h-5 text-red-500" />
          </motion.div>
        </div>

        <AnimatePresence>
          {showMessage && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              className="mb-4 p-4 rounded-lg bg-gradient-to-r from-red-900/30 to-black/30 border border-red-500/20"
            >
              <div className="flex items-start space-x-3">
                <Zap className="w-6 h-6 text-red-500 animate-pulse flex-shrink-0 mt-1" />
                <div className="space-y-2">
                  <p className="font-mono text-sm text-gray-300">
                    <span className="text-red-400 font-bold">anon</span>, you're
                    not just drawing—you're marking your place in history!
                  </p>
                  <p className="font-mono text-xs text-gray-400">
                    rumor has it that the most active artists might find
                    themselves with something
                    <span className="text-red-400 font-bold">
                      {" "}
                      absolutely insane
                    </span>{" "}
                    when we hit mainnet...
                    <span className="text-yellow-400">👀</span>
                  </p>
                  <p className="font-mono text-xs text-gray-500">
                    keep painting, keep earning $QNS, and maybe... just maybe...
                    <span className="text-red-400">
                      you'll be glad you did
                    </span>{" "}
                    🚀
                  </p>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <div className="space-y-4">
          <motion.div
            className="relative bg-black/50 rounded-lg overflow-hidden backdrop-blur-sm border border-red-500/20"
            whileHover={{ boxShadow: "0 0 20px rgba(220, 38, 38, 0.2)" }}
          >
            <canvas
              ref={canvasRef}
              width={400}
              height={400}
              onMouseDown={startDrawing}
              onMouseMove={draw}
              onMouseUp={stopDrawing}
              onMouseLeave={stopDrawing}
              onTouchStart={startDrawing}
              onTouchMove={draw}
              onTouchEnd={stopDrawing}
              className="mx-auto cursor-crosshair"
            />
          </motion.div>

          <motion.div
            className="flex flex-wrap gap-2 mb-4 p-2 bg-black/30 rounded-lg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            {COLORS.map((color) => (
              <motion.button
                key={color}
                whileHover={{ scale: 1.2, rotate: 360 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setBrushColor(color)}
                className={`w-8 h-8 rounded-full border-2 transition-transform ${
                  brushColor === color
                    ? "border-white shadow-lg shadow-red-500/20"
                    : "border-transparent"
                }`}
                style={{ backgroundColor: color }}
              />
            ))}
          </motion.div>

          <div className="flex space-x-2">
            <motion.button
              whileHover={{
                scale: 1.02,
                boxShadow: "0 0 20px rgba(220, 38, 38, 0.3)",
              }}
              whileTap={{ scale: 0.98 }}
              onClick={buyMorePaint}
              className="flex-1 py-2 px-4 bg-gradient-to-r from-red-600 to-red-700 rounded-lg flex items-center justify-center space-x-2 font-mono relative overflow-hidden group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-red-500/20 to-transparent transform -skew-x-12 transition-transform group-hover:translate-x-full" />
              <Paintbrush className="w-5 h-5" />
              <span>Buy More Paint (2 QUAI)</span>
            </motion.button>

            <motion.button
              whileHover={{
                scale: 1.02,
                boxShadow: "0 0 20px rgba(220, 38, 38, 0.3)",
              }}
              whileTap={{ scale: 0.98 }}
              onClick={handleSave}
              disabled={!canDraw || !account}
              className="flex-1 py-2 px-4 bg-gradient-to-r from-red-600 to-red-700 rounded-lg flex items-center justify-center space-x-2 font-mono relative overflow-hidden group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-red-500/20 to-transparent transform -skew-x-12 transition-transform group-hover:translate-x-full" />
              <Save className="w-5 h-5" />
              <span>Save Drawing</span>
            </motion.button>
          </div>

          {!canDraw && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-center text-red-400 font-mono bg-black/30 rounded-lg py-2"
            >
              Reset in: {Math.floor(timeLeft / 60)}:
              {(timeLeft % 60).toString().padStart(2, "0")}
            </motion.div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default CommunityCanvas;
