import { quais } from "quais";
import { buildRpcUrl, dispatchAccount } from "./util";
import { toast } from "react-hot-toast";

export const requestAccounts = async (dispatch) => {
  const requestAccount = async (provider) => {
    let account;
    await provider
      .send("quai_requestAccounts")
      .then((accts) => {
        account = dispatchAccount(accts, dispatch);
      })
      .catch((err) => {

        console.error("Error getting accounts" + err);
        toast.error("Error getting accounts");
      });

    return account;
  };

  if (!window.ethereum) {
    dispatch({
      type: "SET_PROVIDER",
      payload: { web3: undefined, rpc: undefined },
    });
    toast.error("Please install Pelagus Wallet");
    return;
  } else {
    let provider = window.ethereum;
    if (window.ethereum.providers?.length) {
      window.ethereum.providers.find(async (p) => {
        if (p.isPelagus) provider = p;
      });
    }
    if (provider.isPelagus) {
      const web3provider = new quais.providers.Web3Provider(provider);
      requestAccount(web3provider).then((acct) => {
        if (acct) {
          const signer = web3provider.getSigner();

          const rpcProvider = new quais.providers.JsonRpcProvider(
            buildRpcUrl(acct.shard.rpcName)
          );
          dispatch({ type: "SET_SIGNER", payload: signer });
          dispatch({
            type: "SET_PROVIDER",
            payload: { web3: web3provider, rpc: rpcProvider },
          });
        } else {
          dispatch({
            type: "SET_PROVIDER",
            payload: { web3: web3provider, rpc: undefined },
          });
        }
      });
    } else {
      toast.error("Please install Pelagus Wallet");
      dispatch({
        type: "SET_PROVIDER",
        payload: { web3: undefined, rpc: undefined },
      });
      console.log(provider)
    }
  }
};
