import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Bot } from 'lucide-react';

const VerificationMessage = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="p-6 rounded-xl bg-gradient-to-br from-red-900/20 to-black border border-red-500/20 relative overflow-hidden mb-8"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-red-500/5 to-transparent animate-pulse" />
      
      <div className="relative flex items-center space-x-4">
        <div className="flex-shrink-0">
          <Bot className="w-8 h-8 text-red-500 animate-bounce" />
        </div>
        <div>
          <h3 className="text-xl font-mono font-bold text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-red-300">
            Prove You're Not a Bot!
          </h3>
          <p className="text-gray-400 font-mono mt-2">
            fren, we need to make sure you're not a bot trying to steal our precious .quai names! 
            complete the smol verification below and you'll be ready to grab your digital identity! 🚀
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default VerificationMessage;