import React, { useState } from "react";
import { motion } from "framer-motion";
import { Search, AlertCircle } from "lucide-react";
import toast from "react-hot-toast";
import { quais } from "quais";
import useWeb3 from "../hooks/useWeb3";
import { QnsFactoryAbi } from "../config/abi";
import { QnsFactoryAddress } from "../config/contracts";

const NameSearch = () => {
  const { account, signer } = useWeb3();

  const [name, setName] = useState("");
  const [isAvailable, setIsAvailable] = useState(null);
  const isVerified = true;
  const [loading, setLoading] = useState(false);

  const signMessage = async () => {
    const message = 'Sign this message to verify account ownership';
		await window.pelagus
			.request({
				method: 'personal_sign',
				params: [message, account.addr],
			})
			.then((signature) => console.log("Message signature: ", signature))
			.catch((error) => console.log(error));
	};

  const checkAvailability = async () => {
    if (!account) {
      toast.error("Connect your wallet first!");
      return;
    }
    if (!isVerified) {
      toast(
        (t) => (
          <div className="flex items-center space-x-2">
            <AlertCircle className="w-5 h-5 text-yellow-400" />
            <span>Please complete Twitter verification first!</span>
          </div>
        ),
        {
          style: {
            background: "linear-gradient(to right, #dc2626, #991b1b)",
            color: "#ffffff",
            borderRadius: "10px",
          },
          duration: 3000,
        }
      );
      return;
    }
    if(!name) {
      toast.error("Enter a name first!");
      return;
    }
    if(name.length < 5) {
      toast.error("Name must be at least 5 characters long");
      return;
    }

    try {
      const QnsFactory = new quais.Contract(
        QnsFactoryAddress,
        QnsFactoryAbi,
        signer
      );

      const isAvailable = await QnsFactory.isNameAvailable(name);
      setIsAvailable(isAvailable);
    } catch (e) {
      console.log(e);
      toast.error("Error checking availability");
    }
  };

  const handleMint = async () => {
    if (!isVerified) {
      toast.error("Complete Twitter verification first!");
      return;
    }

    try {
      setLoading(true);
      const QnsFactory = new quais.Contract(
        QnsFactoryAddress,
        QnsFactoryAbi,
        signer
      );

      
      await signMessage();
      const cost = await QnsFactory.MINT_COST();
      const tnx = await QnsFactory.mintName(name, {
        value: cost,
        gasLimit: 1000000,
      });

      console.log("Transaction broadcasted:", tnx.hash);
      const receipt = await tnx.wait();
      toast.success("Transaction Mined:", receipt.transactionHash);

      toast(
        (t) => (
          <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-2">
              <span className="text-lg">🎉 Congratulations!</span>
            </div>
            <p>You've minted {name}.quai!</p>
            <p className="text-sm text-yellow-400">+100 $QNS tokens!</p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              onClick={() => {
                const tweetText = encodeURIComponent(
                  `🚀 Just secured ${name}.quai on @Quai_NS!\n\n+100 $QNS in the bag! 💰\n\nJoin the future of digital identity on @QuaiNetwork! Don't let your frens miss out! 🔥\n\n#QuaiSZN #Web3`
                );
                window.open(
                  `https://twitter.com/intent/tweet?text=${tweetText}`,
                  "_blank"
                );
              }}
              className="px-4 py-2 bg-blue-500 rounded-lg text-sm"
            >
              Share on Twitter
            </motion.button>
          </div>
        ),
        {
          duration: 5000,
          style: {
            background: "linear-gradient(to right, #dc2626, #991b1b)",
            color: "#ffffff",
            borderRadius: "10px",
          },
        }
      );

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Error", error);
      setLoading(false);
    }
    setLoading(false);

  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="p-6 rounded-xl bg-gradient-to-br from-red-900/20 to-black border border-red-500/20 relative overflow-hidden"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-red-500/5 to-transparent animate-pulse" />

      <div className="relative">
        <h2 className="text-2xl font-mono font-bold text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-red-300 mb-4">
          Search Your .quai Name
        </h2>

        <div className="space-y-4">
          <div className="relative">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your desired name"
              className="w-full px-4 py-3 bg-black/50 border border-red-500/30 rounded-lg focus:outline-none focus:border-red-500 text-white placeholder-gray-500 font-mono"
            />
            <span className="absolute right-4 top-3 text-gray-400 font-mono">
              .quai
            </span>
          </div>

          <motion.button
            whileHover={{
              scale: 1.02,
              boxShadow: "0 0 20px rgba(220, 38, 38, 0.3)",
            }}
            whileTap={{ scale: 0.98 }}
            onClick={checkAvailability}
            className="w-full py-3 px-6 bg-gradient-to-r from-red-600 to-red-700 rounded-lg flex items-center justify-center space-x-2 font-mono relative overflow-hidden group"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-red-500/20 to-transparent transform -skew-x-12 transition-transform group-hover:translate-x-full" />
            <Search className="w-5 h-5" />
            <span>Check Availability (5 QUAI)</span>
          </motion.button>

          {isAvailable !== null && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className={`p-4 rounded-lg ${
                isAvailable
                  ? "bg-green-500/20 text-green-400"
                  : "bg-red-500/20 text-red-400"
              } font-mono`}
            >
              {isAvailable ? (
                <div className="space-y-2">
                  <p>Name is available!</p>
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={handleMint}
                    className="w-full py-2 px-4 bg-green-600 rounded-lg"
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="flex items-center justify-center">
                        <svg
                          className="animate-spin h-5 w-5 mr-3 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <circle cx="12" cy="12" r="10" strokeWidth="4" />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14 2C14 3.10457 13.1046 4 12 4C10.8954 4 10 3.10457 10 2"
                          />
                        </svg>
                        Loading...
                      </span>
                    ) : (
                      "Mint Now"
                    )}
                  </motion.button>
                </div>
              ) : (
                "Name is already taken"
              )}
            </motion.div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default NameSearch;
