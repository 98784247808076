import React, { useState, useContext } from "react";
import { motion } from "framer-motion";
import { Wallet } from "lucide-react";
import useWeb3 from "../hooks/useWeb3";
import { requestAccounts } from "../utils/requestAccounts";
import { DispatchContext } from "../context/web3Contexts";
import { shortenAddress } from "../utils/util";
const WalletConnect = () => {
  const [loadConnect, setLoadConnect] = useState(false);
  const { account } = useWeb3();
  const dispatch = useContext(DispatchContext);
  async function connect() {
    setLoadConnect(true);
    try {
      await requestAccounts(dispatch);
      setLoadConnect(false);
    } catch (e) {
      setLoadConnect(false);
      console.error(e);
    }
  }

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      className="p-6 rounded-xl bg-gradient-to-br from-red-900/20 to-black border border-red-500/20"
    >
      <h2 className="text-2xl font-bold mb-4">Connect Wallet</h2>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={connect}
        disabled={loadConnect}
        className="w-full py-3 px-6 bg-red-600 hover:bg-red-700 rounded-lg flex items-center justify-center space-x-2 transition-colors"
      >
        {loadConnect ? (
          <span className="flex items-center justify-center">
            <svg
              className="animate-spin h-5 w-5 mr-3 text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <circle cx="12" cy="12" r="10" strokeWidth="4" />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14 2C14 3.10457 13.1046 4 12 4C10.8954 4 10 3.10457 10 2"
              />
            </svg>
            Loading...
          </span>
        ) : (
          <>
            <Wallet className="w-5 h-5" />
            <span>
              {account ? (
                <>
                  Connected to {account.shard.name} :
                  {shortenAddress(account.addr)}
                </>
              ) : (
                "Connect Wallet"
              )}
            </span>
          </>
        )}
      </motion.button>
    </motion.div>
  );
};

export default WalletConnect;
