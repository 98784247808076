import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Trophy } from 'lucide-react';

const MOCK_DATA = Array.from({ length: 500 }, (_, i) => ({
  rank: i + 1,
  name: `user${i + 1}.quai`,
  qns: Math.floor(Math.random() * 10000),
}));

const Leaderboard = () => {
  const [page, setPage] = useState(0);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(MOCK_DATA.length / itemsPerPage);

  const currentData = MOCK_DATA.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  );

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="p-6 rounded-xl bg-gradient-to-br from-red-900/20 to-black border border-red-500/20"
    >
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-2xl font-bold">Leaderboard</h2>
        <Trophy className="w-6 h-6 text-red-500" />
      </div>

      <div className="space-y-2">
        <AnimatePresence mode="wait">
          {currentData.map((item, index) => (
            <motion.div
              key={item.rank}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ delay: index * 0.05 }}
              className="flex items-center justify-between p-3 rounded-lg bg-black/30 border border-red-500/10"
            >
              <div className="flex items-center space-x-4">
                <span className="w-8 text-center font-mono text-red-500">
                  #{item.rank}
                </span>
                <span className="font-medium">{item.name}</span>
              </div>
              <span className="font-mono text-red-400">{item.qns} QNS</span>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      <div className="mt-4 flex justify-between items-center">
        <button
          onClick={() => setPage(Math.max(0, page - 1))}
          disabled={page === 0}
          className="p-2 rounded-lg bg-red-600/20 hover:bg-red-600/30 disabled:opacity-50"
        >
          <ChevronLeft className="w-5 h-5" />
        </button>
        
        <span className="text-gray-400">
          Page {page + 1} of {totalPages}
        </span>
        
        <button
          onClick={() => setPage(Math.min(totalPages - 1, page + 1))}
          disabled={page === totalPages - 1}
          className="p-2 rounded-lg bg-red-600/20 hover:bg-red-600/30 disabled:opacity-50"
        >
          <ChevronRight className="w-5 h-5" />
        </button>
      </div>
    </motion.div>
  );
};

export default Leaderboard;