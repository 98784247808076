import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";


const typeStateMap = {
  SET_ACCOUNT: "account",
  SET_PROVIDER: "provider",
  SET_SIGNER: "signer",
};

const initialState = {
  account: undefined,
  signer: undefined,
  provider: { web3: undefined, rpc: undefined },
};

const reducer = (state, action) => {
  const stateName = typeStateMap[action.type];
  if (!stateName) {
    console.warn(`Unknown action type: ${action.type}`);

    return state;
  }

  return { ...state, [stateName]: action.payload };

};

const Web3Context = createContext(initialState);
const DispatchContext = createContext(null);


Web3Provider.propTypes = {
  children: PropTypes.node,
};

function Web3Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
    return (
      <DispatchContext.Provider value={dispatch}>
        <Web3Context.Provider value={state}>{children}</Web3Context.Provider>
      </DispatchContext.Provider>
    );
}

export { Web3Provider, Web3Context, DispatchContext , typeStateMap};
