import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Twitter, ExternalLink, Check, Sparkles } from 'lucide-react';
import toast from 'react-hot-toast';

const TwitterVerification = ({ account }) => {
  const [username, setUsername] = useState('');
  const [step, setStep] = useState(1);
  const [tweetLink, setTweetLink] = useState('');

  const handleFollow = () => {
    window.open('https://x.com/Quai_NS', '_blank');
    setStep(2);
  };

  const handleRetweet = () => {
    window.open('https://x.com/Quai_NS', '_blank');
    setStep(3);
  };

  const handleTweet = () => {
    const tweetText = encodeURIComponent(
      `Just minted my .quai and joined the ranks of the bold. This isn't just a name—it's an identity, a statement, a key to the future. Qns dripping, and the chosen rising. You're either in or you're watching history happen without you. Welcome to the circle. @Quai_NS @QuaiNetwork #QuaiSZN`
    );
    window.open(`https://twitter.com/intent/tweet?text=${tweetText}`, '_blank');
    setStep(4);
  };

  const verifyTweetLink = () => {
    if (tweetLink.includes(`twitter.com/${username}`) || tweetLink.includes(`x.com/${username}`)) {
      toast((t) => (
        <div className="flex items-center space-x-2">
          <Sparkles className="w-5 h-5 text-yellow-400 animate-spin" />
          <span>Verification successful! Ready to mint your .quai name!</span>
        </div>
      ), {
        style: {
          background: 'linear-gradient(to right, #dc2626, #991b1b)',
          color: '#ffffff',
          borderRadius: '10px',
        },
        duration: 3000,
      });
      return true;
    }
    toast.error('Invalid tweet link. Please ensure you used your correct Twitter account.');
    return false;
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="p-6 rounded-xl bg-gradient-to-br from-red-900/20 to-black border border-red-500/20 relative overflow-hidden"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-red-500/5 to-transparent animate-pulse" />
      
      <div className="relative">
        <motion.h2 
          className="text-2xl font-mono font-bold text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-red-300 mb-4"
          whileHover={{ scale: 1.05 }}
        >
          Twitter Verification
        </motion.h2>

        <div className="space-y-4">
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              className="relative"
            >
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Your Twitter username"
                className="w-full px-4 py-3 bg-black/50 border border-red-500/30 rounded-lg focus:outline-none focus:border-red-500 text-white placeholder-gray-500 font-mono"
              />
            </motion.div>
          </AnimatePresence>

          {[
            { step: 1, icon: Twitter, text: "Follow QNS", action: handleFollow },
            { step: 2, icon: ExternalLink, text: "Like & Retweet Latest Post", action: handleRetweet },
            { step: 3, icon: Twitter, text: "Tweet About QNS", action: handleTweet }
          ].map((button, index) => (
            <motion.button
              key={button.step}
              whileHover={{ scale: 1.02, boxShadow: "0 0 20px rgba(220, 38, 38, 0.3)" }}
              whileTap={{ scale: 0.98 }}
              onClick={button.action}
              disabled={step !== button.step}
              className={`w-full py-3 px-6 rounded-lg flex items-center justify-center space-x-2 transition-all duration-300 ${
                step === button.step ? 'bg-gradient-to-r from-red-600 to-red-700' : 'bg-gray-700'
              } font-mono relative overflow-hidden group`}
            >
              <div className="absolute inset-0 bg-gradient-to-r from-red-500/20 to-transparent transform -skew-x-12 transition-transform group-hover:translate-x-full" />
              <button.icon className="w-5 h-5" />
              <span>{`${index + 1}. ${button.text}`}</span>
              {step > button.step && <Check className="w-5 h-5 ml-2" />}
            </motion.button>
          ))}

          {step === 4 && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              className="relative space-y-2"
            >
              <input
                type="text"
                value={tweetLink}
                onChange={(e) => setTweetLink(e.target.value)}
                placeholder="Paste your tweet link"
                className="w-full px-4 py-3 bg-black/50 border border-red-500/30 rounded-lg focus:outline-none focus:border-red-500 text-white placeholder-gray-500 font-mono"
              />
              <motion.button
                whileHover={{ scale: 1.02, boxShadow: "0 0 20px rgba(220, 38, 38, 0.3)" }}
                whileTap={{ scale: 0.98 }}
                onClick={verifyTweetLink}
                className="w-full py-2 px-4 bg-gradient-to-r from-red-600 to-red-700 rounded-lg font-mono relative overflow-hidden group"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-red-500/20 to-transparent transform -skew-x-12 transition-transform group-hover:translate-x-full" />
                <span>Verify Tweet</span>
              </motion.button>
            </motion.div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default TwitterVerification;